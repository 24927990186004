import { default as declinerxQtvuZTRB2Meta } from "/vercel/path0/pages/[id]/decliner.vue?macro=true";
import { default as indexLTNpcMlpSgMeta } from "/vercel/path0/pages/[id]/index.vue?macro=true";
import { default as confirmation6KgE7OGcq9Meta } from "/vercel/path0/pages/[id]/postuler/confirmation.vue?macro=true";
import { default as indexOXUte7bbgiMeta } from "/vercel/path0/pages/[id]/postuler/index.vue?macro=true";
import { default as recapitulatifzoNkJRUAqFMeta } from "/vercel/path0/pages/[id]/postuler/recapitulatif.vue?macro=true";
import { default as indexl38lMVEgqRMeta } from "/vercel/path0/pages/connexion/index.vue?macro=true";
import { default as linkedinc6HO8hjWAyMeta } from "/vercel/path0/pages/connexion/linkedin.vue?macro=true";
import { default as verificationhbuPDLIlv2Meta } from "/vercel/path0/pages/connexion/verification.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexNz2ZjWClNNMeta } from "/vercel/path0/pages/jobs/[id]/index.vue?macro=true";
export default [
  {
    name: "id-decliner",
    path: "/:id()/decliner",
    component: () => import("/vercel/path0/pages/[id]/decliner.vue")
  },
  {
    name: "id",
    path: "/:id()",
    component: () => import("/vercel/path0/pages/[id]/index.vue")
  },
  {
    name: "id-postuler-confirmation",
    path: "/:id()/postuler/confirmation",
    component: () => import("/vercel/path0/pages/[id]/postuler/confirmation.vue")
  },
  {
    name: "id-postuler",
    path: "/:id()/postuler",
    meta: indexOXUte7bbgiMeta || {},
    component: () => import("/vercel/path0/pages/[id]/postuler/index.vue")
  },
  {
    name: "id-postuler-recapitulatif",
    path: "/:id()/postuler/recapitulatif",
    component: () => import("/vercel/path0/pages/[id]/postuler/recapitulatif.vue")
  },
  {
    name: "connexion",
    path: "/connexion",
    component: () => import("/vercel/path0/pages/connexion/index.vue")
  },
  {
    name: "connexion-linkedin",
    path: "/connexion/linkedin",
    component: () => import("/vercel/path0/pages/connexion/linkedin.vue")
  },
  {
    name: "connexion-verification",
    path: "/connexion/verification",
    component: () => import("/vercel/path0/pages/connexion/verification.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "jobs-id",
    path: "/jobs/:id()",
    component: () => import("/vercel/path0/pages/jobs/[id]/index.vue")
  }
]