export const appLayoutTransition = {"name":"page","mode":"out-in"}

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"link":[{"rel":"stylesheet","href":"https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&family=Epilogue:wght@400;500;600;700&display=swap"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"property":"og:image","content":"og-image.jpg"},{"property":"og:image:type","content":"image/jpeg"},{"property":"og:image:width","content":"1200"},{"property":"og:image:height","content":"628"},{"name":"twitter:image","content":"og-image.jpg"},{"name":"twitter:image:type","content":"image/jpeg"},{"name":"twitter:image:width","content":"1200"},{"name":"twitter:image:height","content":"628"},{"property":"og:type","content":"website"}],"style":[],"script":[],"noscript":[],"title":"Recrutement par recommandation | proppl.io","htmlAttrs":{"lang":"fr"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"