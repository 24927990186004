export default defineAppConfig({
  ui: {
    strategy: 'override',
    primary: 'blue',
    gray: 'slate',
    notifications: {
      // Top centered notifications
      position: 'top-0 right-0',
    },
    button: {
      default: {
        loadingIcon: 'mdi-loading',
      },
      font: 'font-bold font-serif',
      size: {
        xl: 'text-sm',
      },
      padding: {
        xl: 'px-5 py-3.5',
      }
    }
  },
})
