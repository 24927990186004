export const USER_TOKEN_KEY = 'proppl_token';
export const INVITATION_ID_KEY = 'proppl_invitation_id';

export const FETCH_STATUS = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

export const DISPONIBILITY = {
  'now': 'Maintenant',
  '1month': '1 mois de préavis',
  '2month': '2 mois de préavis',
  '3month': '3 mois et plus de préavis',
};

// Function to get year from date string
export function getYear(dateString: string | null): number {
  if (!dateString) return new Date().getFullYear();
  return new Date(dateString).getFullYear();
}
