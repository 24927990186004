import { useScriptSegment, useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const segment = useScriptSegment({"writeKey":"vgwivySGhtWVgTqjU9YwnbI1U1ssAe3d"})
    return { provide: { $scripts: { segment } } }
  }
})